import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CTA from "../../components/cta"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServiceAreas from "../../components/serviceAreas"
import HeroSection from "../../components/heroSection"
import SubServicesAlt from "../../components/subServicesAlt"
import Education from "../../components/education"
import Testimonials from "../../components/testimonials"

function Sinks() {
  const data = useStaticQuery(graphql`
    query sinkImages {
      sink: file(relativePath: { eq: "sink-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Faucet Services in North Houston" />
      <HeroSection
        h1={`Sink Repair Services`}
        h2={`Have an Experienced Master Plumber Fix Your Sink`}
        h3={`A working sink is vital to a smoothly-running home. If your kitchen or bathroom sink isn't functioning properly, our experienced, professional team is able to help.`}
        p1={`Klein Plumbing Services has 30 years of experience repairing sinks, so whether it's an issue with your sink's faucet, spout, drain, or stopper, we can fix it quickly. Our service is fast, professional, and affordable - talk with a master plumber today.`}
        heroImg={data.sink.childImageSharp.fluid}
      />
      <Education
        mainTitle={`Signs Your Sink Needs Repair`}
        titleOne={`It's Constantly Dripping`}
        descriptionOne={`A healthy sink's faucet doesn't regularly drip. If you experience a consistent dripping, it's a sign that your sink needs to be repaired.`}
        titleTwo={`It's Leaking`}
        descriptionTwo={`Leaks below your sink are a surefire sign that it needs a repair. Besides causing water damage, it can also result in a higher water bill (and a huge mess!)`}
        titleThree={`It's Not Stopping`}
        descriptionThree={`A normal sink is able to be plugged and fill up. A broken stopper needs to be replaced for your sink to function like it's supposed to.`}
      />
      <SubServicesAlt
        mainTitle={`Our Services`}
        titleOne={`Leak Repair`}
        descriptionOne={`We can diagnose and fix your leak no matter where it's coming from so your faucet doesn't cause permanent damage and mold.`}
        titleTwo={`Drain Unclogging`}
        descriptionTwo={`We're experts in locating and breaking up a clog in your sink's drain using proven methods and precision tools.`}
        titleThree={`Damaged Component Repair`}
        descriptionThree={`We can repair/replace non-functioning stoppers, strainers, and more. If your sink has general wear and tear, our repairs can help extend its life.`}
      />
      {/* <Testimonials /> */}
      <ServiceAreas description={`sinks`} subDescription={`sink repairs`} />
      <CTA title={`Get a Free Quote to Fix Your Sink Now`} />
    </Layout>
  )
}

export default Sinks
